import { ASSETS } from '@/assets';
import AnchorInfoModel from '@/compnents/anchorInfo/model';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  copyToClipboard,
  exportXlsx,
  getWxUrl,
  request,
  requireSource,
  transformKeys,
} from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Tag, Tooltip, message } from 'antd';
import { action, observable } from 'mobx';
import AlternativeModel from '../employer/employerManagement/alternativeSlice/model';
import DetailModel from '../employer/employerManagement/employerDetail/model';
import type { SysDictVO } from '../finance/financeDetail/type';
import { ChangeBorkerModel } from './changeBorkerModal/model';
import type { ListItem } from './interface';

interface Option {
  label: any;
  value: string;
  id?: string | number | null;
  name?: string;
  children?: Option[];
  isLeaf?: boolean;
}

const POSITION_STATUS_DICT = [
  { label: '已合作', value: '1' },
  { label: '未合作', value: '2' },
];
const SIGNUP_TYPE = [
  { label: '自主报名', value: '1' },
  { label: '人工报名', value: '2' },
];
const INVITE_TYPE = [
  { label: '自主邀约', value: '1' },
  { label: '人工邀约', value: '2' },
];

const JOBTYPE_DICT = [
  {
    label: '全职',
    value: '1',
  },
  {
    label: '兼职',
    value: '2',
  },
  {
    label: '临时兼职',
    value: '3',
  },
];

export default class Store {
  constructor() {
    this.mainStore.grid.onQuery();
    this.init();
  }

  private exportParams = {};
  @observable public historyVisible = false;

  @observable public historyData: ListItem;

  @observable public refuseVisible = false;

  @observable public refuseData: ListItem;

  @observable public confirmVisible = false;

  @observable public confirmData: ListItem;

  @observable public interviewType: 'new' | 'edit' = 'new'; // new 发起面试，edit 编辑面试

  @observable public interviewVisible = false;

  @observable public interviewData: ListItem;

  @observable public cancelVisible = false;

  @observable public cancelData: ListItem;

  @observable public anchorEvaVisible = false;

  @observable public anchorEvaData: ListItem;

  @observable public anchorEvaType: 'new' | 'edit' = 'new'; // new 主播评价，edit 查看主播评价

  @observable public employerEvaVisible = false;

  @observable public employerEvaData: ListItem;

  @observable public employerEvaType: 'new' | 'edit' = 'new'; // new 雇主评价，edit 查看雇主评价

  @observable public applyPaymentVisible = false;

  @observable public applyPaymentData: ListItem;

  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框

  @observable statusDict = null; //状态字典

  @observable signupSourceDict = null; // 报名来源字典

  public alternativeSliceStore = new AlternativeModel(this);

  public employerDetailStore = new DetailModel(this);

  public changeBorkerStore = new ChangeBorkerModel(this);

  init = () => {
    //主播标签
    request<BaseData<Option[]>>({
      url: '/pbb/platform/sys/tag/lib/list/all',
      method: 'get',
      params: { categoryType: 5 },
    }).then((categoriesList) => {
      this.mainStore.programme.filterItems.updateFilterItem([
        {
          field: 'tagIds',
          treeData: transformKeys(
            categoriesList.data.filter((e) => e.children.length > 0),
            {
              name: 'label',
              id: 'value',
            },
          ),
        },
      ]);
    });

    request<BaseData<{ [key: string]: SysDictVO[] }>>({
      url: '/pbb/platform/anon/system/sysDict/getDictMap',
      method: 'POST',
      data: {
        dictType: ['sys_recruit_status', 'register_channel'],
      },
    }).then((res) => {
      this.statusDict = transformKeys(res.data.sys_recruit_status, {
        dictName: 'label',
        dictValue: 'value',
      });
      this.signupSourceDict = transformKeys(res.data.register_channel, {
        dictName: 'label',
        dictValue: 'value',
      });
      this.mainStore.programme.filterItems.updateFilterItem([
        { field: 'status', data: this.statusDict },
        { field: 'signUpSource', data: this.signupSourceDict },
      ]);
    });
  };
  // 历史进度
  @action public openHistory = (item: ListItem) => {
    this.historyData = item;
    this.historyVisible = true;
  };
  //确认邀约
  @action public openSure = async (item: ListItem) => {
    const res = await request<BaseData<boolean>>({
      url: '/pbb/platform/sys/recruit/checkEmployerHasInviteCount',
      method: 'POST',
      data: {
        recruitId: item.recruitId,
      },
    });
    if (res.data) {
      Modal.confirm({
        icon: null,
        closable: true,
        content: (
          <div className="text-center py-5">
            <div className="mb-2">是否已与雇主确认邀约该主播</div>
            <div>确认后将冻结邀约次数，到面后扣除</div>
          </div>
        ),
        onOk: async () => {
          const res = await request<BaseData>({
            url: '/pbb/platform/sys/recruit/confirmToInvite',
            method: 'POST',
            data: {
              recruitId: item.recruitId,
            },
          });
          message.success('邀约成功');
          this.onRefresh();
        },
      });
    } else {
      Modal.confirm({
        icon: null,
        closable: true,
        content: (
          <div className="text-center py-5">
            <div className="mb-2">该雇主没有到面邀约次数</div>
            <div>需联系雇主充值后确认</div>
          </div>
        ),
        okButtonProps: { block: true },
        okText: '到面服务链接   复制',
        cancelButtonProps: { className: 'hidden' },
        onOk: async () => {
          try {
            const str = await getWxUrl({
              query: 'packageType=7',
              channel: 8,
            });
            copyToClipboard(str);
            message.success('复制成功');
          } catch (error) {
            console.error(error);
            message.error('复制失败');
          }
        },
      });
    }
  };
  // 拒绝邀约
  @action public openRefuse = (item: ListItem) => {
    this.refuseData = item;
    this.refuseVisible = true;
  };
  //报名确认
  @action public openConfirm = (item: ListItem) => {
    this.confirmData = item;
    this.confirmVisible = true;
  };

  @action public openInterview = (type: 'new' | 'edit', item?: any) => {
    this.interviewData = item;
    this.interviewType = type;
    this.interviewVisible = true;
  };

  @observable openCancel = (item: ListItem) => {
    this.cancelData = item;
    this.cancelVisible = true;
  };

  @action public openAnchorEva = (type: 'new' | 'edit', item: ListItem) => {
    this.anchorEvaType = type;
    this.anchorEvaData = item;
    this.anchorEvaVisible = true;
  };

  @action public openEmployerEva = (type: 'new' | 'edit', item: ListItem) => {
    this.employerEvaType = type;
    this.employerEvaData = item;
    this.employerEvaVisible = true;
  };

  @action public openApplyPayment = (item: ListItem) => {
    this.applyPaymentData = item;
    this.applyPaymentVisible = true;
  };

  @action public refreshData = () => {
    this.mainStore.grid.gridModel.onRefresh();
  };

  @action public onShare = async (params, text) => {
    const url = await getWxUrl(params);
    // const copyText = text + url;
    copyToClipboard(text + url);
  };

  @action onRefresh = () => {
    this.mainStore.grid.onQuery();
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    showCollapse: false,
    filterItems: [
      {
        type: 'select',
        field: 'jobType',
        label: '职位类型',
        data: JOBTYPE_DICT,
      },
      {
        type: 'input',
        field: 'positionName',
        label: '职位名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'employerParam',
        label: '雇主信息',
        placeholder: '请输入昵称/手机号/公司名称/ID',
      },
      {
        type: 'input',
        field: 'anchorParam',
        label: '主播信息',
        placeholder: '请输入昵称/姓名/手机号/ID',
      },
      {
        type: 'input',
        field: 'recommenderParam',
        label: '报名推荐人',
        placeholder: '请输入推荐人昵称/姓名/手机号/公司名称/ID',
      },
      {
        type: 'input',
        field: 'butlerName',
        label: '管家姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'brokerName',
        label: '经纪人',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'positionStatus',
        label: '岗位状态',
        data: POSITION_STATUS_DICT,
      },
      {
        type: 'select',
        field: 'signUpType',
        label: '报名类型',
        data: SIGNUP_TYPE,
      },
      {
        type: 'select',
        field: 'signUpSource',
        label: '报名来源',
      },
      {
        type: 'select',
        field: 'inviteType',
        label: '邀约类型',
        data: INVITE_TYPE,
      },
      {
        type: 'select',
        field: 'status',
        label: '状态',
      },
      {
        type: 'dateRange',
        field: 'dateRange',
        startParamsField: 'updateTimeStart',
        endParamsField: 'updateTimeEnd',
        label: '更新时间',
      },
      {
        type: 'treeSelect',
        field: 'tagIds',
        label: '主播标签',
        placeholder: '请选择',
        multiple: true,
        isParamList: true,
        treeCheckable: true,
        showCheckedStrategy: 'SHOW_CHILD',
        allowClear: true,
      },
      {
        type: 'select',
        field: 'isVirtual',
        label: '雇主类型',
        data: [
          {
            label: '真实雇主',
            value: '0',
          },
          {
            label: '虚拟雇主',
            value: '1',
          },
        ],
      },
      {
        type: 'dateRange',
        field: 'createTime',
        endParamsField: 'createTimeEnd',
        startParamsField: 'createTimeStart',
        label: '创建时间',
      },
      {
        type: 'select',
        field: 'sourceType',
        label: '发起方',
        data: [
          { label: '主播', value: '1' },
          { label: '雇主', value: '2' },
        ],
      },
      {
        type: 'dateRange',
        field: '主播注册时间',
        startParamsField: 'anchorCreateTimeStart',
        endParamsField: 'anchorCreateTimeEnd',
        label: '主播注册时间',
      },
      {
        type: 'dateRange',
        field: '雇主注册时间',
        startParamsField: 'employerCreateTimeStart',
        endParamsField: 'employerCreateTimeEnd',
        label: '雇主注册时间',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '批量更换经纪人',
        permissionId: '121',
        handleClick: () => {
          console.log(1111);

          this.changeBorkerStore.onShow(Array.from(this.mainStore.grid.gridModel.selectedIds));
        },
      },
      {
        permissionId: '92',
        text: '导出',
        iconNode: <UploadOutlined />,
        handleClick: () => {
          exportXlsx('/pbb/platform/sys/recruit/list/export', this.exportParams, '求职招聘');
        },
      },
    ],
    grid: {
      rowHeight: 110,
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 240,
          formatter: ({ row }) => {
            // const pageId = 53;
            return (
              <div className="flex flex-wrap items-center w-full h-full">
                <RenderByPermission permissionId={'53_30'}>
                  <Button
                    type="link"
                    onClick={() => this.openHistory(row)}
                  >
                    历史进度
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={'53_121'}>
                  <Button
                    type="link"
                    onClick={() => this.changeBorkerStore.onShow([row.recruitId])}
                  >
                    更换经纪人
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={'53_31'}>
                  {(row.status === 1 || row.status === 2) && (
                    <Button
                      type="link"
                      onClick={() => {
                        window.top.RingPermission.openTab(
                          `/pbb-pc-management/positionManagement/index?positionId=${row.positionId}&isShowDetail=${true}`,
                          '76',
                          '职位管理',
                        );
                      }}
                    >
                      查看JD
                    </Button>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={'53_40'}>
                  {row.status === 1 && (
                    <>
                      <Button
                        type="link"
                        onClick={() =>
                          this.onShare(
                            { channel: 6, anchorId: row.anchorInfo?.userId },
                            '你的管家给你推荐了主播，快来看看~',
                          )
                        }
                      >
                        分享主播
                      </Button>
                      <Button
                        type="link"
                        onClick={() => this.openRefuse(row)}
                      >
                        拒绝邀约
                      </Button>
                      <Button
                        type="link"
                        onClick={() => this.openSure(row)}
                      >
                        确认邀约
                      </Button>
                    </>
                  )}
                </RenderByPermission>
                {row.status === 2 && (
                  <>
                    <RenderByPermission permissionId={'53_32'}>
                      <Button
                        type="link"
                        onClick={() =>
                          this.onShare(
                            { channel: 1, positionId: row.positionId },
                            '你的经纪人给你推荐了职位，快来看看~',
                          )
                        }
                      >
                        分享JD
                      </Button>
                    </RenderByPermission>
                    {/* <RenderByPermission permissionId={'53_33'}>
                      <Button
                        type="link"
                        onClick={() => this.openConfirm(row)}
                      >
                        报名确认
                      </Button>
                    </RenderByPermission> */}
                  </>
                )}
                {row.isShowFeedBackButton === 1 && (
                  <RenderByPermission permissionId={'53_37'}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.openEmployerEva('edit', row);
                      }}
                    >
                      到面反馈
                    </Button>
                  </RenderByPermission>
                )}
                {(row.isUserFeedback === 1 || row.status === 6 || row.status === 8) && (
                  <RenderByPermission permissionId={'53_39'}>
                    <Button
                      type="link"
                      onClick={() => this.openApplyPayment(row)}
                    >
                      发红包
                    </Button>
                  </RenderByPermission>
                )}
              </div>
            );
          },
        },
        {
          key: 'anchorInfo',
          name: '主播信息',
          width: 290,
          formatter: ({ row }) => {
            const { anchorInfo } = row;
            if (!anchorInfo) {
              return '';
            }
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(anchorInfo.userId);
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={anchorInfo.avatar}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {anchorInfo.hasFee ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({anchorInfo.feeTimes})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    用户昵称：{anchorInfo.userName}
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{anchorInfo.userId}</div>
                  <div>手机号：{anchorInfo.mobile}</div>
                  <div>最后活跃时间：{anchorInfo.lastActiveTime}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'anchorTags',
          name: '主播标签',
          formatter: ({ row }) => {
            const { anchorTags } = row;
            const nodes = anchorTags?.map((item) => {
              return (
                <Tag
                  key={item.libId}
                  color="green"
                >
                  {item.libName}
                </Tag>
              );
            });
            return <Tooltip title={nodes}>{nodes}</Tooltip>;
          },
        },
        {
          key: 'jobType',
          name: '职位类型',
          formatter: ({ row }) => {
            const item = JOBTYPE_DICT.find((item) => Number(item.value) === row.jobType);
            return item?.label;
          },
        },
        {
          key: 'positionName',
          name: '职位名称',
          width: 300,
          formatter: ({ row }) => {
            return (
              <div
                className="text-primary cursor-pointer"
                onClick={() => {
                  window.top.RingPermission.openTab(
                    `/pbb-pc-management/positionManagement/index?positionId=${row.positionId}&isShowDetail=${true}`,
                    '76',
                    '职位管理',
                  );
                }}
              >
                {row.positionName}
              </div>
            );
          },
        },
        {
          key: 'employerInfo',
          name: '雇主信息',
          width: 290,
          formatter: ({ row }) => {
            const { employerInfo } = row;
            if (!employerInfo) {
              return '';
            }
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  //弹出雇主信息资料
                  this.employerDetailStore.onOpenDetailModal(employerInfo.userId, row.positionId);
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={employerInfo.avatar || 'https://xbygobs.bocaixingtu.cn/wx-employer/mine/avatar-default.png'}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {employerInfo.hasFee ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({employerInfo.feeTimes})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    用户昵称：{employerInfo.userName}
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{employerInfo.userId}</div>
                  <div>手机号：{employerInfo.mobile}</div>
                  <div>公司名称：{employerInfo.companyName}</div>
                  <div>最后活跃时间：{employerInfo.lastActiveTime}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'employerType',
          name: '雇主类型',
          formatter: ({ row }) => {
            const { employerInfo } = row;
            if (employerInfo?.isVirtual === undefined || employerInfo?.isVirtual === null) {
              return '';
            }
            return employerInfo.isVirtual === 1 ? '虚拟雇主' : '真实雇主';
          },
        },
        {
          key: 'brokerName',
          name: '经纪人',
          width: 150,
        },
        {
          key: 'butlerName',
          name: '管家',
          width: 150,
        },
        {
          key: 'positionStatus',
          name: '岗位状态',
          width: 150,
          formatter: ({ row }) => {
            const item = POSITION_STATUS_DICT.find((item) => Number(item.value) == row.positionStatus);
            return item?.label;
          },
        },
        {
          key: 'status',
          name: '状态',
          formatter: ({ row }) => {
            const item = this.statusDict.find((item) => Number(item.value) === row.status);
            return item?.label;
          },
        },
        {
          key: 'sourceType',
          name: '发起方',
          formatter: ({ row }) => {
            return row.sourceType === 1 ? '主播' : '雇主';
          },
        },
        {
          key: 'signUpType',
          name: '报名类型',
          formatter: ({ row }) => {
            const item = SIGNUP_TYPE.find((item) => Number(item.value) === row.signUpType);
            return item?.label;
          },
        },
        {
          key: 'inviteType',
          name: '邀约类型',
          formatter: ({ row }) => {
            const item = INVITE_TYPE.find((item) => Number(item.value) === row.inviteType);
            return item?.label;
          },
        },
        {
          key: 'signUpSource',
          name: '报名来源',
          formatter: ({ row }) => {
            const item = this.signupSourceDict.find((item) => Number(item.value) === row.signUpSource);
            return item?.label;
          },
        },
        {
          key: 'recommenderInfo',
          name: '报名推荐人',
          width: 250,
          formatter: ({ row }) => {
            const { recommenderInfo } = row;
            if (!recommenderInfo) {
              return '';
            }
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">用户昵称：{recommenderInfo.userName}</div>
                  <div>{recommenderInfo.userId ? `ID：${recommenderInfo.userId}` : null}</div>
                  <div>{recommenderInfo.mobile ? `手机号：${recommenderInfo.mobile}` : null}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'updateTime',
          name: '更新时间',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'recruitId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        this.exportParams = postParams;
        return request<PaginationData<ListItem>>({
          url: '/pbb/platform/sys/recruit/getRecruitPage',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
